import perKilo from "./perKilo.json";
import perItem from "./perItem.json";
import zones from "./zones.json";
import levy from "../data/levy.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = zones.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule || weight > 25 || length > 180) {
    //console.log("CP No shipping rule found for this postcode.");
    return 99999;
  }
  const shippingRatePerItem = perItem.find(
    (data) => data["TO Zone"] === shippingRule.zone
  );
  if (!shippingRatePerItem) {
    //console.log("CP No shipping rule found for this postcode.");
    return 99999;
  }
  let shippingCostPerItem = 0;

  if (weight <= 0.5) {
    shippingCostPerItem += shippingRatePerItem["500g"];
  } else if (weight <= 1) {
    shippingCostPerItem += shippingRatePerItem["1kg"];
  } else if (weight <= 3) {
    shippingCostPerItem += shippingRatePerItem["3kg"];
  } else if (weight <= 5) {
    shippingCostPerItem += shippingRatePerItem["5kg"];
  } else if (weight <= 10) {
    shippingCostPerItem += shippingRatePerItem["10kg"];
  } else if (weight <= 15) {
    shippingCostPerItem += shippingRatePerItem["15kg"];
  } else {
    shippingCostPerItem += shippingRatePerItem["25kg"];
  }

  const shippingRatePerKilo = perKilo.find(
    (data) => data["Destination"] === shippingRule.zone
  );
  if (!shippingRatePerKilo) {
    //console.log("CP No shipping rule found for this postcode.");
    return 99999;
  }

  let shippingCostPerKilo =
    parseFloat(shippingRatePerKilo["Basic"]) +
    parseFloat(shippingRatePerKilo["PerKg"]) * parseFloat(weight);
  shippingCostPerKilo = Math.min(
    shippingCostPerKilo,
    shippingRatePerKilo["Minimum"]
  );

  if (shippingRatePerKilo["Flat 25kgs"] !== 0) {
    shippingCostPerKilo = shippingRatePerKilo["Flat 25kgs"];
  }

  return (
    Math.min(shippingCostPerItem, shippingCostPerKilo) * (1 + levy.cp) +
    (length < 120 ? 0 : 10 * (1 + levy.cp))
  );
};

//=IF(AND(length<=180,weight<=25),MIN(VLOOKUP(D1303,'Per Item SY Rate'!B:J,9,FALSE),VLOOKUP(D1303,'Base & Per Kilo SY Rate'!B:G,6,FALSE))*(1+levy+IF(length<120,0,10*(1+levy),"")
