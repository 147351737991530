import basicData from "./data.json";
import surcharge from "./surcharge.json";
import levy from "../data/levy.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = basicData.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule) {
    //console.log("Hunter No shipping rule found for this postcode.");
    return 99999;
  }

  /*
  let additionalFee = 0;

  if (length <= 120 && weight <= 30) {
    additionalFee = 0;
  } else if (length <= 199 && weight <= 35) {
    additionalFee = 12;
  } else if (length <= 299 && weight <= 55) {
    additionalFee = 50;
  } else {
    additionalFee = 75;
  }
    */

  let shippingCost =
    parseFloat(shippingRule["basic charge"]) +
    parseFloat(shippingRule["per kilo"]) * parseFloat(Math.ceil(weight));
  shippingCost =
    Math.max(shippingCost, shippingRule["minimum"]) * (1 + levy.hunter);

  const extra = surcharge.find((s) => s.weight && weight <= s.weight);
  const extra2 = surcharge.find((s) => s.length && length <= s.length);

  if (extra) {
    shippingCost += extra.amount * (1 + levy.hunter);
  }
  if (extra2) {
    shippingCost += extra2.amount * (1 + levy.hunter);
  }

  return shippingCost;
};

/*
=IF(E3+(CEILING(weight,1)*F3)>G3,(E3+(CEILING(weight,1)*F3))*(1+levy),G3*(1+levy))+IF(weight<=35,0,IF(weight<=49,10*(1+levy),IF(weight<=74,20*(1+levy),IF(weight<=99,30*(1+levy),50*(1+levy)))))+IF(length<150,0,IF(length<=249,10*(1+levy),IF(length<=399,50*(1+levy),IF(length<=599,200*(1+levy),400*(1+levy)))))
*/
