import data from "./data.json";
import surcharge from "./surcharge.json";
import levy from "../data/levy.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = data.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule) {
    //console.log("Allied No shipping rule found for this postcode.");
    return 99999;
  }

  let shippingCost =
    parseFloat(shippingRule["basic charge"]) +
    parseFloat(shippingRule["per kilo"]) * parseFloat(Math.ceil(weight));
  shippingCost =
    Math.max(shippingCost, shippingRule["minimum"]) * (1 + levy.allied);

  const extra = surcharge.find((s) => s.weight && weight <= s.weight);
  const extra2 = surcharge.find((s) => s.length && length <= s.length);

  if (extra) {
    shippingCost += extra.amount * (1 + levy.allied);
  }
  if (extra2) {
    shippingCost += extra2.amount * (1 + levy.allied);
  }

  return shippingCost;
};

/*
=(IF(E5320+(weight*F5320)>G5320,(E5320+(weight*F5320))*(1+levy),G5320*(1+levy))+IF(weight<=22,8*(1+levy),IF(weight<=55,16*(1+levy),IF(weight<=90,55*(1+levy),120*(1+levy))))+IF(length<=120,0,IF(length<=239,5.4*(1+levy),IF(length<=359,11.93*(1+levy),IF(length<=419,25.4*(1+levy),IF(length<=479,88.61*(1+levy),IF(length<=599,119.19*(1+levy),153.91*(1+levy))))))))
*/
