import basicData from "./data.json";
import levy from "../data/levy.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = basicData.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule || weight > 25 || length > 180) {
    //console.log("Fastway No shipping rule found for this postcode.");
    return 99999;
  }
  let shippingCost;
  if (weight <= 0.5) {
    shippingCost = shippingRule["0-500G"];
  } else if (weight <= 1) {
    shippingCost = shippingRule["500G-1KG"];
  } else if (weight <= 2) {
    shippingCost = shippingRule["1KG-2KG"];
  } else if (weight <= 3) {
    shippingCost = shippingRule["2KG-3KG"];
  } else if (weight <= 4) {
    shippingCost = shippingRule["3KG-4KG"];
  } else if (weight <= 5) {
    shippingCost = shippingRule["4KG-5KG"];
  } else {
    shippingCost =
      parseFloat(shippingRule["4KG-5KG"]) +
      parseFloat(shippingRule["0.5 KG after "]) * 2 * (parseFloat(weight) - 5);
  }

  shippingCost = shippingCost * (1 + levy.fastway);

  if (length >= 120) {
    shippingCost += 6 * (1 + levy.fastway);
  }

  return shippingCost;
};

/*
=IF(AND(length<=180,weight<=25),IF(weight<=0.5,E1303*(1+levy),IF(weight<=1,F1303*(1+levy),IF(weight<=2,G1303*(1+levy),IF(weight<=3,H1303*(1+levy),IF(weight<=4,I1303*(1+levy),IF(weight<=5,J1303*(1+levy),(J1303+(K1303*2*(weight-5)))*(1+levy)))))))+IF(length<120,0,6*(1+levy)),"")
*/
