import React, { useState, useCallback } from "react";
import * as XLSX from "xlsx";
import { getFreeZoneShipping, getAllShipping } from "./Func";

const ExcelProcessor = () => {
  const [progress, setProgress] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [fileName, setFileName] = useState("");

  // Example calculation function - replace with your actual calculation
  const calculateForRow = async (data) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Replace this with your actual calculation logic
        if (
          data.length > 0 &&
          data.width > 0 &&
          data.height > 0 &&
          data.weight > 0
        ) {
          const result = {
            ...data,
            AllShippingFee: getAllShipping(
              data.length,
              data.width,
              data.height,
              data.weight
            ),
            freeZoneFee: getFreeZoneShipping(
              data.length,
              data.width,
              data.height,
              data.weight
            ),
          };
          resolve(result);
        } else {
          resolve(data);
        }
      }, 100);
    });
  };

  const processChunk = useCallback(async (data, startIndex = 0) => {
    if (startIndex >= data.length) {
      setIsProcessing(false);
      return;
    }

    try {
      // Process current item
      const result = await calculateForRow(data[startIndex]);

      setResults((prev) => [...prev, result]);
      setCurrentItem(startIndex + 1);
      setProgress(((startIndex + 1) / data.length) * 100);

      // Schedule next chunk with setTimeout to prevent UI blocking
      setTimeout(() => {
        processChunk(data, startIndex + 1);
      }, 0);
    } catch (err) {
      setError(`Error processing item ${startIndex + 1}: ${err.message}`);
      setIsProcessing(false);
    }
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsProcessing(true);
      setProgress(0);
      setCurrentItem(0);
      setResults([]);
      setError(null);
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          if (jsonData.length === 0) {
            throw new Error("No data found in the Excel file");
          }

          // Start processing from the beginning
          processChunk(jsonData);
        } catch (err) {
          setError(`Error parsing Excel file: ${err.message}`);
          setIsProcessing(false);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
      setError(`Error reading file: ${err.message}`);
      setIsProcessing(false);
    }
  };

  const handleExport = () => {
    if (results.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(results);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Processed Data");

    // Generate filename with timestamp
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const exportFileName = `processed_${
      fileName.split(".")[0]
    }_${timestamp}.xlsx`;

    XLSX.writeFile(workbook, exportFileName);
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Excel Data Processor</h5>
        </div>
        <div className="card-body">
          {/* File Upload */}
          <div className="mb-3">
            <input
              type="file"
              className="form-control"
              accept=".xlsx,.xls"
              onChange={handleFileUpload}
              disabled={isProcessing}
            />
          </div>

          {/* Processing Status */}
          {isProcessing && (
            <div className="mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span>Processing item {currentItem}...</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {Math.round(progress)}%
                </div>
              </div>
            </div>
          )}

          {/* Error Display */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {/* Results */}
          {results.length > 0 && (
            <div className="mt-4">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="mb-0">Processed Results:</h6>
                <button
                  className="btn btn-success btn-sm"
                  onClick={handleExport}
                >
                  Export Results
                </button>
              </div>
              <div style={{ maxHeight: "300px", overflow: "auto" }}>
                <table className="table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      {Object.keys(results[0]).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((result, index) => (
                      <tr key={index}>
                        {Object.values(result).map((value, valueIndex) => (
                          <td key={valueIndex}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcelProcessor;
