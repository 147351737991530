import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import { calculateShipping as DFcalculateShipping } from "./df";
import { calculateShipping as AlliedcalculateShipping } from "./allied";
import { calculateShipping as PostcalculateShipping } from "./aupost";
import { calculateShipping as CPcalculateShipping } from "./cp";
import { calculateShipping as FastwaycalculateShipping } from "./fastway";
import { calculateShipping as HuntercalculateShipping } from "./hunter";

import orders from "./mockOrders.json";

import smallProductFreePostcodes from "./data/smallFreePostcodes.json";
import largeProductFreePostcodes from "./data/largeFreePostcodes.json";
import allPostcodes from "./data/allPostcodes.json";

function App() {
  const [result, setResult] = useState(null);
  const [testDeliveryResult, setTestDeliveryResult] = useState(null);
  const [ordersCountTable, setOrdersCountTable] = useState([]);
  const [weight, setWeight] = useState(0);
  const [inputWeight, setInputWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [allShipping, setAllShipping] = useState(0);
  const [freeZoneShipping, setFreeZoneShipping] = useState(0);
  const [isLarge, setIsLarge] = useState(true);
  const [testPostcode, setTestpostcode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Get product orders count from uploaded orders file
  useEffect(() => {
    // output uploaded history orders

    if (orders && orders.length > 0) {
      console.log(orders);
      setOrdersCountTable(countOrdersByPostcode(orders));
    }
  }, []);

  // Paste from excel
  const [inputValue, setInputValue] = useState("");

  const handlePaste = (event) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData("text");

    // Convert Excel cell data into comma-separated values
    const formattedData = pastedData
      .trim() // Remove any surrounding whitespace
      .split("\n") // Split rows by new lines
      .map((row) => row.split("\t").join(", ")) // Split columns by tabs and join as comma-separated values
      .join(", ");

    setInputValue(formattedData);

    // Convert Excel cell data into an array by splitting rows and columns
    const values = pastedData
      .trim()
      .split("\n")[0] // Get the first row
      .split("\t"); // Split columns by tabs

    setLength(parseFloat(values[0]));
    setWidth(parseFloat(values[1]));
    setHeight(parseFloat(values[2]));
    setInputWeight(parseFloat(values[3]));
  };

  const handleChange = (event) => {
    const input = event.target.value;

    setInputValue(input);
    // Convert comma-separated input into an array
    const values = input.split(",").map((val) => val.trim());

    setLength(parseFloat(values[0]));
    setWidth(parseFloat(values[1]));
    setHeight(parseFloat(values[2]));
    setInputWeight(parseFloat(values[3]));
  };

  // Function to count orders by postcode
  const countOrdersByPostcode = (orders) => {
    const postcodeCounts = {};

    orders.forEach((order) => {
      const { postcode } = order;
      if (postcodeCounts[postcode]) {
        postcodeCounts[postcode]++;
      } else {
        postcodeCounts[postcode] = 1;
      }
    });

    return postcodeCounts;
  };

  // Check large product or not
  useEffect(() => {
    const calcWeight = (length * width * height) / 4000;

    if (
      (length <= 100 && inputWeight <= 22) ||
      (length <= 180 && calcWeight <= 25)
    ) {
      setIsLarge(false);
    } else {
      setIsLarge(true);
    }
    setWeight(Math.max(calcWeight, inputWeight));
  }, [length, width, height, inputWeight, inputValue]);

  // Set calculated result matrix
  const calculateShipping = () => {
    setIsLoading(true);
    let shippingValue = [];

    if (weight > 0 && ordersCountTable) {
      let freePostcodes = isLarge
        ? largeProductFreePostcodes
        : smallProductFreePostcodes;

      if (inputWeight <= 5 && length <= 100) {
        freePostcodes = allPostcodes;
      }

      console.log("ordersCountTable");
      console.log(ordersCountTable);

      // All orders are from postcodes beyond our free shipping postcode, for example we can't count order from postcode 110.
      const ordersCountTableMostZone = allPostcodes.reduce((acc, curr) => {
        const key = curr.postcode; // Extract the `postcode` value from the object
        if (ordersCountTable.hasOwnProperty(key)) {
          acc[key] = ordersCountTable[key]; // Add matching key-value pair to result object
        }
        return acc;
      }, {});
      console.log("ordersCountTableMostZone");
      console.log(ordersCountTableMostZone);

      const ordersCountTableFreeZone = freePostcodes.reduce((acc, curr) => {
        const key = curr.postcode; // Extract the `postcode` value from the object
        if (ordersCountTable.hasOwnProperty(key)) {
          acc[key] = ordersCountTable[key]; // Add matching key-value pair to result object
        }
        return acc;
      }, {});

      console.log("ordersCountTableFreeZone");
      console.log(ordersCountTableFreeZone);

      const sumOrdersAll = Object.values(ordersCountTableMostZone).reduce(
        (acc, value) => acc + value,
        0
      );
      const sumOrdersAllFreeZone = Object.values(
        ordersCountTableFreeZone
      ).reduce((acc, value) => acc + value, 0);

      allPostcodes.forEach((obj) => {
        const postcode = obj.postcode;

        const df = DFcalculateShipping(postcode, weight, length);
        const allied = AlliedcalculateShipping(postcode, weight, length);
        // AU Post use input weight to calculate
        const aupost = PostcalculateShipping(postcode, inputWeight, length);
        const cp = CPcalculateShipping(postcode, weight, length);
        const fastway = FastwaycalculateShipping(postcode, weight, length);
        const hunter = HuntercalculateShipping(postcode, weight, length);

        // get percentage from all orders in current postcode
        const allOrdersPercentage = ordersCountTableMostZone[postcode]
          ? ordersCountTableMostZone[postcode] / sumOrdersAll
          : 0;

        // get percentage from free shipping zone orders in current postcode
        const ordersPercentage = ordersCountTableFreeZone[postcode]
          ? ordersCountTableFreeZone[postcode] / sumOrdersAllFreeZone
          : 0;

        const minShippingFee = Math.min(
          df,
          allied,
          aupost,
          cp,
          fastway,
          hunter
        );

        shippingValue.push({
          postcode,
          allied,
          aupost,
          df,
          cp,
          fastway,
          hunter,
          minShippingFee,
          allOrders: ordersCountTableMostZone[postcode],
          allOrdersPercentage,
          allOrdersRate:
            parseFloat(minShippingFee) < 9999
              ? parseFloat(allOrdersPercentage) * parseFloat(minShippingFee)
              : 0,
          ordersFreeZone: ordersCountTableFreeZone[postcode],
          ordersPercentage,
          freeZoneOrdersRate:
            parseFloat(minShippingFee) < 9999
              ? parseFloat(ordersPercentage) * parseFloat(minShippingFee)
              : 0,
        });
      });

      setAllShipping(
        shippingValue.reduce((acc, curr) => {
          return acc + curr.allOrdersRate; // Add the `postcode` value to the accumulator
        }, 0)
      );
      setFreeZoneShipping(
        shippingValue.reduce((acc, curr) => {
          return acc + curr.freeZoneOrdersRate; // Add the `postcode` value to the accumulator
        }, 0)
      );
    } else {
      alert("no weight");
    }

    setResult(shippingValue);
  };

  useEffect(() => {
    if (result && result.length > 0) {
      setIsLoading(false);
      console.log("all data result");
      console.log(result);
    }
  }, [result]);

  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const ws = XLSX.utils.json_to_sheet(result);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate a downloadable Excel file
    XLSX.writeFile(wb, `data.xlsx`);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Shipping Calculator</h1>
      {/*
      <label>上传历史订单：</label>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        className="mb-4 p-2 border rounded"
      />
      */}
      <div className="mb-3">
        <label>长，宽，高，重量: </label>
        <input
          type="text"
          className="form-control"
          placeholder="Length, Width, Height, Weight"
          value={inputValue}
          onChange={handleChange}
          onPaste={handlePaste}
        />
      </div>
      <div className="mb-3">体积重量：{weight}</div>
      <div className="mb-3">
        <label className="form-label">测试用 postcode</label>
        <input
          type="number"
          className="form-control"
          id="testPostcode"
          value={testPostcode}
          onChange={(e) => setTestpostcode(e.target.value)}
        />
      </div>
      {weight > 0 && testPostcode && (
        <div>
          <h2>测试：</h2>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = AlliedcalculateShipping(
                  testPostcode,
                  weight,
                  length
                );
                setTestDeliveryResult(fee);
              }}
            >
              测试Allied
            </button>
          </div>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = PostcalculateShipping(
                  testPostcode,
                  inputWeight,
                  length
                );
                setTestDeliveryResult(fee);
              }}
            >
              测试AUPost
            </button>
          </div>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = DFcalculateShipping(testPostcode, weight, length);
                setTestDeliveryResult(fee);
              }}
            >
              测试DF
            </button>
          </div>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = CPcalculateShipping(testPostcode, weight, length);
                setTestDeliveryResult(fee);
              }}
            >
              测试CP
            </button>
          </div>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = FastwaycalculateShipping(
                  testPostcode,
                  weight,
                  length
                );
                setTestDeliveryResult(fee);
              }}
            >
              测试Fastway
            </button>
          </div>
          <div className="mb-3">
            <button
              onClick={() => {
                const fee = HuntercalculateShipping(
                  testPostcode,
                  weight,
                  length
                );
                setTestDeliveryResult(fee);
              }}
            >
              测试Hunter
            </button>
          </div>
          <div className="mb-3">测试结果：{testDeliveryResult}</div>
        </div>
      )}
      {orders && (
        <div className="mb-4">
          <button onClick={calculateShipping} className="btn btn-primary">
            Calculate
          </button>
        </div>
      )}
      {isLoading && <h2>Loading</h2>}
      {result && result.length > 0 && (
        <div className="mt-4">
          <h2>Result:</h2>
          <p>平均运费：{allShipping}</p>
          <p>免邮区平均运费：{freeZoneShipping}</p>
          <button onClick={exportToExcel}>download excel</button>
        </div>
      )}
    </div>
  );
}

export default App;
