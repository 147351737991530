import { calculateShipping as DFcalculateShipping } from "./df";
import { calculateShipping as AlliedcalculateShipping } from "./allied";
import { calculateShipping as PostcalculateShipping } from "./aupost";
import { calculateShipping as CPcalculateShipping } from "./cp";
import { calculateShipping as FastwaycalculateShipping } from "./fastway";
import { calculateShipping as HuntercalculateShipping } from "./hunter";

import orders from "./mockOrders.json";

import smallProductFreePostcodes from "./data/smallFreePostcodes.json";
import largeProductFreePostcodes from "./data/largeFreePostcodes.json";
import allPostcodes from "./data/allPostcodes.json";

// Function to count orders by postcode
const countOrdersByPostcode = (orders) => {
  const postcodeCounts = {};

  orders.forEach((order) => {
    const { postcode } = order;
    if (postcodeCounts[postcode]) {
      postcodeCounts[postcode]++;
    } else {
      postcodeCounts[postcode] = 1;
    }
  });

  return postcodeCounts;
};

const setWeight = (length, width, height, inputWeight) => {
  const calcWeight = (length * width * height) / 4000;
  return Math.max(calcWeight, inputWeight);
};

const setIsLarge = (length, width, height, inputWeight) => {
  const calcWeight = (length * width * height) / 4000;

  if (
    (length <= 100 && inputWeight <= 22) ||
    (length <= 180 && calcWeight <= 25)
  ) {
    return false;
  } else {
    return true;
  }
};

const getShippingValue = (length, width, height, inputWeight) => {
  const ordersCountTable = countOrdersByPostcode(orders);

  let shippingValue = [];
  const weight = setWeight(length, width, height, inputWeight);
  const isLarge = setIsLarge(length, width, height, inputWeight);

  if (weight > 0 && ordersCountTable) {
    let freePostcodes = isLarge
      ? largeProductFreePostcodes
      : smallProductFreePostcodes;

    if (inputWeight <= 5 && length <= 100) {
      freePostcodes = allPostcodes;
    }

    // All orders are from postcodes beyond our free shipping postcode, for example we can't count order from postcode 110.
    const ordersCountTableMostZone = allPostcodes.reduce((acc, curr) => {
      const key = curr.postcode; // Extract the `postcode` value from the object
      if (ordersCountTable.hasOwnProperty(key)) {
        acc[key] = ordersCountTable[key]; // Add matching key-value pair to result object
      }
      return acc;
    }, {});

    const ordersCountTableFreeZone = freePostcodes.reduce((acc, curr) => {
      const key = curr.postcode; // Extract the `postcode` value from the object
      if (ordersCountTable.hasOwnProperty(key)) {
        acc[key] = ordersCountTable[key]; // Add matching key-value pair to result object
      }
      return acc;
    }, {});

    const sumOrdersAll = Object.values(ordersCountTableMostZone).reduce(
      (acc, value) => acc + value,
      0
    );
    const sumOrdersAllFreeZone = Object.values(ordersCountTableFreeZone).reduce(
      (acc, value) => acc + value,
      0
    );

    allPostcodes.forEach((obj) => {
      const postcode = obj.postcode;

      const df = DFcalculateShipping(postcode, weight, length);
      const allied = AlliedcalculateShipping(postcode, weight, length);
      // AU Post use input weight to calculate
      const aupost = PostcalculateShipping(postcode, inputWeight, length);
      const cp = CPcalculateShipping(postcode, weight, length);
      const fastway = FastwaycalculateShipping(postcode, weight, length);
      const hunter = HuntercalculateShipping(postcode, weight, length);

      // get percentage from all orders in current postcode
      const allOrdersPercentage = ordersCountTableMostZone[postcode]
        ? ordersCountTableMostZone[postcode] / sumOrdersAll
        : 0;

      // get percentage from free shipping zone orders in current postcode
      const ordersPercentage = ordersCountTableFreeZone[postcode]
        ? ordersCountTableFreeZone[postcode] / sumOrdersAllFreeZone
        : 0;

      const minShippingFee = Math.min(df, allied, aupost, cp, fastway, hunter);

      shippingValue.push({
        postcode,
        allied,
        aupost,
        df,
        cp,
        fastway,
        hunter,
        minShippingFee,
        allOrders: ordersCountTableMostZone[postcode],
        allOrdersPercentage,
        allOrdersRate:
          parseFloat(minShippingFee) < 9999
            ? parseFloat(allOrdersPercentage) * parseFloat(minShippingFee)
            : 0,
        ordersFreeZone: ordersCountTableFreeZone[postcode],
        ordersPercentage,
        freeZoneOrdersRate:
          parseFloat(minShippingFee) < 9999
            ? parseFloat(ordersPercentage) * parseFloat(minShippingFee)
            : 0,
      });
    });

    return shippingValue;
  }
};

export const getFreeZoneShipping = (length, width, height, inputWeight) => {
  const shippingValue = getShippingValue(length, width, height, inputWeight);

  return shippingValue.reduce((acc, curr) => {
    return acc + curr.freeZoneOrdersRate; // Add the `postcode` value to the accumulator
  }, 0);
};

export const getAllShipping = (length, width, height, inputWeight) => {
  const shippingValue = getShippingValue(length, width, height, inputWeight);

  return shippingValue.reduce((acc, curr) => {
    return acc + curr.allOrdersRate; // Add the `postcode` value to the accumulator
  }, 0);
};
