import basicData from "./data.json";
import levy from "../data/levy.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = basicData.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule || weight >= 22 || length >= 100) {
    //console.log("AU post No shipping rule found for this postcode.");
    return 99999;
  }
  let shippingCost;
  if (weight <= 0.5) {
    shippingCost = shippingRule["500g"];
  } else if (weight <= 1) {
    shippingCost = shippingRule["1kg"];
  } else if (weight <= 3) {
    shippingCost = shippingRule["3kg"];
  } else if (weight <= 5) {
    shippingCost = shippingRule["5kg"];
  } else {
    shippingCost =
      parseFloat(shippingRule["Basic"]) +
      parseFloat(shippingRule["Subsequent"]) +
      parseFloat(shippingRule["Per Kg"]) * parseFloat(Math.ceil(weight));
  }
  return shippingCost * (1 + levy.aupost);
};
