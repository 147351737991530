import data from "./data.json";
//import remote from "./remote.json";
import levy from "../data/levy.json";
import surcharge from "./surcharge.json";

export const calculateShipping = (postcode, weight, length) => {
  const shippingRule = data.find(
    (rule) => parseInt(rule.postcode) === parseInt(postcode)
  );
  if (!shippingRule) {
    //console.log("DF No shipping rule found for this postcode.");
    return 99999;
  }

  let shippingCost =
    parseFloat(shippingRule["basic charge"]) +
    parseFloat(shippingRule["per kilo"]) * parseFloat(Math.ceil(weight));
  shippingCost =
    Math.max(shippingCost, shippingRule["minimum"]) * (1 + levy.df);

  const extra = surcharge.find((s) => s.weight && weight <= s.weight);
  const extra2 = surcharge.find((s) => s.length && length <= s.length);

  if (extra) {
    shippingCost += extra.amount * (1 + levy.df);
  }
  if (extra2) {
    shippingCost += extra2.amount * (1 + levy.df);
  }

  /*
  // Need to double check
  const re = remote.find((r) => parseInt(r.Postcode) === parseInt(postcode));
  if (re) {
    shippingCost += re.Amount * (1 + levy.df);
  }
    */

  return shippingCost * 1.1;
};

/*
=IF(
  E1303+(CEILING(weight,1)*F1303)>G1303,(E1303+(CEILING(weight,1)*F1303))*(1+levy),G1303*(1+levy))+
  IF(
    weight<=60,7*(1+levy),
      IF(weight<=90,14*(1+levy),
        IF(weight<=120,21*(1+levy),
          IF(weight<=150,28*(1+levy),35*(1+levy)))
        )
    )+
    IF(length<150,
        0,
        IF(length<=199,8*(1+levy),
          IF(length<=299,18*(1+levy),
            IF(length<=399,35*(1+levy),
              IF(length<=499,90*(1+levy),
                IF(length<=599,240*(1+levy),460*(1+levy))
              )
            )
          )
        ))+
    IF(ISERROR(VLOOKUP(A1303,remote,2,0)),0,(VLOOKUP(A1303,remote,2,0)+(VLOOKUP(A1303,remote,3,0)*CEILING(weight,1)))*(1+levy))
    */
